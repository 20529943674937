<template>
  <div class="side-tool">
    <div class="side-tool-item" @click="goPage('/feedback')">
      <div><i class="el-icon-edit-outline item-icon"></i></div>
      <div><span>意见反馈</span></div>
    </div>
    <div class="side-tool-item">
      <div><i class="el-icon-service item-icon"></i></div>
      <div><span>在线客服</span></div>
      <div class="item-detail">
        <p style="background: #f6f6f6; padding: 4px; margin: -7px -7px 0 -7px;">客服</p>
        <p style=" margin-top: 10px;">{{ $store.state.setting.contactUsTel }}</p>
        <p style=" margin-top: 10px;">{{ $store.state.setting.contactUsTime }}</p>
        <el-button slot="reference"
                   @click="openDialog"
                   style="background: red; color: #fff; padding: 8px; width: 150px; margin-top: 10px;">
          在线客服
        </el-button>
        <br>
        <el-button slot="reference"
                   style="background: red; color: #fff; padding: 8px; width: 150px; margin-top: 10px;">
          <a class="service-qq"
             :href="'http://wpa.qq.com/msgrd?v=3&uin=' + $store.state.setting.contactUsQQ"
             target="_blank" title="联系客服QQ" rel="nofollow" style="color: #fff;">QQ联系</a>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchBar',
  data() {
    return {}
  },
  created() {
    let path = this.$route.path
      if (path === "/"){
        this.showDialog()
      }
  },
  methods: {
    showDialog() {
      let c = document.createElement('script');
      let url = window.location.hostname;
      c.src = "https://web01.aiocs.cn/srapi/vclientV3?tokenkey=Rvzb%2Ba1LgnfAL2pvolslk6FVG%2FkNcxV0m5os0V74FMDtajkfk%2B87rBZPYs0cBIrwa%2F0FbVCYQ8D2hIfKqnMXF%2BW5WwgymqeM&website="
        + url;
      let s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(c, s);
    },
    minDialog() {
      let minDom = document.getElementById('smallView');
      if (minDom) {
        minDom.click();
      }
    },
    openDialog() {
      let maxDom = document.getElementById('maxView');
      if (maxDom) {
        maxDom.click();
      }
      // let userId = this.user && this.user.userId || null;
      // let account = this.user && this.user.account || null;
      // let iWidth = 1000;
      // let iHeight = 600;
      // let iTop = (window.screen.availHeight - 30 - iHeight) / 2;
      // let iLeft = (window.screen.availWidth - 10 - iWidth) / 2;
      // let win = window.open(
      //   `http://aiocs.ihzsr.cn/client?projid=1&website=sctpweb.ihzsr.cn&UUID=1fbb3dda-dcc4-4b4d-a512-be2c1cdf9447&userid=${userId}&nickname=${account}`, "在线客服",
      //   "width=" + iWidth + ", height=" + iHeight + ",top=" + iTop + ",left=" + iLeft
      //   + ",toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no,alwaysRaised=yes,depended=yes");
    }
  }
}
</script>

<style scoped lang="scss">

.side-tool {
  position: fixed;
  right: 0;
  top: 40%;
  transform: translateY(-50%);
  z-index: 99999;
  background-color: rgb(255, 153, 0);

  .side-tool-item:not(:first-child):before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 8px;
    right: 8px;
    border-top: 1px white solid;
  }

  .side-tool-item {
    cursor: pointer;
    color: white;
    padding: 15px 10px;
    text-align: center;
    position: relative;

    &:hover .item-detail {
      color: black;
      width: unset;
      padding: 7px;
    }

    i.item-icon, img.item-icon {
      font-size: 30px;
      vertical-align: sub;
      width: 30px;
      color: white;
      margin-bottom: 5px;
    }

    .item-detail {
      font-size: 13px;
      width: 0;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 100%;
      background-color: #fff;
      padding: 0;
      transition: width 0.5s, background-color 0.5s;
    }
  }
}

</style>
